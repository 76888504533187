import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ScrollToTop from "./helpers/scroll-top";
import ReactGA from 'react-ga';

const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const Product = lazy(() => import("./pages/shop-product/Product"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const SearchResults = lazy(() => import("./pages/search/SearchResults"));
const Successpayment = lazy(() => import("./pages/other/Successpayment"));
const Success = lazy(() => import("./pages/other/Success"));
const Canceled = lazy(() => import("./pages/other/Canceled"));

const TRACKING_ID = "UA-10837927-49";

ReactGA.initialize(TRACKING_ID);

const App = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
    
  return (
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>
              <Route path={process.env.PUBLIC_URL + "/:cat"} element={<ShopGridNoSidebar/>} />
              <Route path={process.env.PUBLIC_URL + "/:cat/:sub"} element={<ShopGridNoSidebar/>} />
              <Route path={process.env.PUBLIC_URL + "/:cat/:sub/:subsub"} element={<ShopGridNoSidebar/>} />
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<HomeFashion/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/noticias"}
                element={<BlogNoSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/noticias/:id"}
                element={<BlogDetailsStandard/>}
              /> 
              <Route
                path={process.env.PUBLIC_URL + "/sobre"}
                element={<About categoria="about"/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/politica_de_privacidade"}
                element={<About categoria="politica_de_privacidade"/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/termos_e_condicoes"}
                element={<About categoria="termos_e_condicoes"/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/resolucao_de_litigios"}
                element={<About categoria="resolucao_de_litigios"/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/politica_de_cookies"}
                element={<About categoria="politica_de_cookies"/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/cart"}
                element={<Cart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                element={<Wishlist/>}
              />
               <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<Checkout/>}
              /> 
              <Route
                path={process.env.PUBLIC_URL + "/search/:searchfor"}
                element={<SearchResults/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/verify-payment"}
                element={<Successpayment/>}
              />
              <Route
                path={process.env.PUBLIC_URL +"/compra-finalizada"}
                element={<Success/>}
              />
              <Route
                path={process.env.PUBLIC_URL +"/pagamento-cancelado"}
                element={<Canceled/>}
              />
              <Route path="*" element={<NotFound/>} />
            </Routes>
          </Suspense>
        </ScrollToTop>
        <CookieConsent style={{background:'#f14110',color:'#ffffff'}} buttonStyle={{background:'#ce9634',color:'#ffffff'}} buttonText="Compreendi e aceito" location="bottom" cookieName="digitirsoCookieInfo" expires={999} overlay>
      Este site usa cookies para melhorar a experiência do utilizador.
      </CookieConsent>
      </Router>
  );
};

export default App;