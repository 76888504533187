
import  { createSlice } from '@reduxjs/toolkit';

const initialState = { products:[], }
const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProducts : (state, action) => {
            state.products = [...action.payload];
        },
    }
});
const { actions, reducer } = productSlice
export const { setProducts } = actions;
export default reducer;
